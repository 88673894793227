.itemImagesGrid {
  padding: 50px;
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.singleItem {
  width: 160px;
  height: 120px;
  cursor: pointer;
  transition: all linear 200ms;
  box-shadow: 0 0 10px 1px #d3d3d3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  border-radius: 10px;
}
@media screen and (min-width: 1400px) {
  .singleItem {
    width: 200px;
    height: 150px;
  }
}
@media screen and (min-width: 1800px) {
  .singleItem {
    width: 200px;
    height: 150px;
  }
}
.singleItemDisabled {
  width: 200px;
  height: 150px;
  cursor: not-allowed;
  transition: all linear 200ms;
  box-shadow: 0 0 10px 2px #d3d3d3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}
.itemImage {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
@media screen and (min-width: 1400px) {
  .itemImage {
    width: 75px;
    height: 75px;
  }
}
@media screen and (min-width: 1800px) {
  .itemImage {
    width: 75px;
    height: 75px;
  }
}
.itemText {
  color: #666;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
}
@media screen and (min-width: 1400px) {
  .itemText {
    font-size: 12px;
  }
}
@media screen and (min-width: 1800px) {
  .itemText {
    font-size: 12px;
  }
}
.itemImageDisabled {
  width: 75px;
  height: 75px;
  margin: 0 auto;
  cursor: not-allowed;
  transition: all linear 200ms;
}
.singleItem:hover {
  cursor: pointer;
  box-shadow: 0 0 10px 2px darkgray;
  transition: all linear 200ms;
}
.createItemCategoryGrid {
  display: grid;
  gap: 10px 30px;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(1, 4rem);
}
.createItemIndiTabArea {
  display: grid;
  gap: 10px 30px;
  grid-template-columns: repeat(16, 1fr);
  padding: 20px;
  grid-template-rows: repeat(1, 4rem);
}
.itemIndiTabActions {
  padding: 10px 50px 10px 20px;
  text-align: end;
}
.createItemGrid {
  display: grid;
  gap: 10px 30px;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(5, 4rem);
}
.itemMultiline {
  grid-column: auto / span 7;
  grid-row: auto / span 2;
}
.itemMultilineInput {
  width: 100%;
  height: calc(7rem);
  box-sizing: border-box;
  border: 1px solid #c8c8c8;
  color: #666;
  font-size: 16px;
  flex: 1;
  background-color: white;
  padding-left: 10px;
}
.itemPhoto {
  box-sizing: border-box;
  border-radius: 5px;
  color: #666;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  grid-column: auto / span 3;
  grid-row: auto / span 2;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
}
.itemLogo {
  margin-top: 5px;
  border: 1px solid #aaa;
  flex: 1;
  background-color: #d3d3d3;
  border-radius: 5px;
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
}
#uploadItemlogo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.createItemIndividualTabDetails {
  display: grid;
  gap: 10px 30px;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(1, 4rem);
  padding: 20px;
}
.createItemPurchaseGrid {
  display: grid;
  gap: 10px 30px;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(1, 4rem);
}
.createItemPurchaseTable {
  margin: 10px auto;
  width: 100%;
  overflow: auto;
  border-collapse: separate;
  border-spacing: 0 1px;
  background-color: lightgray;
}
.createPurchaseCostInput {
  height: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-width: 6rem;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
  font-size: 16px;
  text-align: right;
}
.createPurchaseCostInputError {
  height: 2rem;
  border: 1px solid #ee3f3f;
  border-radius: 5px;
  min-width: 6rem;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
  font-size: 16px;
  text-align: right;
}
.createItemSalePriceGrid {
  display: grid;
  gap: 10px 30px;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(2, 4rem);
}
.createItemTaxInput {
  height: 2rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* min-width: 6rem; */
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 16px;
  text-align: right;
}
.createItemTaxInputError {
  height: 2rem;
  border: 1px solid #ee3f3f;
  border-radius: 5px;
  /* min-width: 6rem; */
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 16px;
  text-align: right;
}
.itemsUploadButtonsDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.itemUpload {
  flex: 1;
  margin: 0 2rem;
  max-width: 25rem;
}
.itemsUploadText {
  width: 100%;
  text-align: center;
  color: #14c76a;
  font-weight: bold;
  font-size: 20px;
}
.itemsUploadGrid {
  margin-top: 1rem;
  width: 100%;
  display: grid;
  gap: 10px 50px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 3rem);
}
.itemsTable {
  margin-top: 1rem;
  width: 100%;
  margin: auto;
  text-align: "center";
  justify-content: "center";
  overflow: auto;
}
.itemsTableHeader {
  border: 1px solid #ccc;
  color: #333;
  border-collapse: collapse;
  padding: 10px 5px;
}
.itemsTableHeaderBold {
  border: 1px solid #ccc;
  color: #333;
  border-collapse: collapse;
  padding: 10px 5px;
  background-color: #f3f3f3;
}
.itemsTableValueBold {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-collapse: collapse;
  white-space: nowrap;
  padding: 5px;
  color: red;
  background-color: #f3f3f3;
}
.itemsTableValue {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-collapse: collapse;
  white-space: nowrap;
  padding: 5px;
}
