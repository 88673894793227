*:focus {
	outline: none;
}
.container {
	width: 100vw;
	height: 100vh;
	background: linear-gradient(203deg, #1f43e5, #14c76a);
	display: flex;
}
.loginColumn {
	background-color: white;
	width: 15rem;
	margin: auto;
	padding: 15px;
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	box-shadow: 2px 2px 5px 2px rgba(255, 255, 255, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.loginPageLogo {
	width: 15rem;
	margin: auto;
	align-items: center;
	justify-content: center;
}
.loginHeader {
	margin: 0.5rem auto 0.5rem;
	width: 100%;
	text-align: center;
	font-size: 20px;
	font-weight: 500;
	color: #1837be;
}
.loginInput {
	padding: 10px;
	margin: 0 10px;
	width: -webkit-fill-available;
	border-radius: 15px;
	font-size: 12px;
	border: 1px solid #ddd;
}
.eyeIcon {
	position: absolute;
	top: 10px;
	right: 20px;
	cursor: pointer;
}
.loginButton {
	width: 9rem;
	margin: 0 auto;
	padding: 5px 5px;
	font-size: 12px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	margin: 0 auto;
	background: #1f43e5;
	color: #fff;
	padding: 0.75rem;
}
.error {
	border: 1px solid #ee3f3f;
}
.errorText {
	margin: 10px 0px;
	font-size: 20px;
	color: #ee3f3f;
	text-align: center;
}
.successText {
	margin: 10px 0px;
	font-size: 20px;
	color: #14c76a;
	text-align: center;
}
.disabled {
	background-color: #5a6ec5;
}

@media screen and (min-width: 1400px) {
	.loginButton {
		width: 12rem;
		font-size: 12px;
		margin: 0 auto;
		padding: 7px 5px;
		border-radius: 10px;
	}
	.loginColumn {
		width: 20rem;
		padding: 25px;
	}
	.loginPageLogo {
		width: 20rem;
	}
	.loginHeader {
		font-size: 24px;
	}
	.loginInput {
		padding: 15px;
		font-size: 16px;
	}
  .eyeIcon {
		top: 17px;
		right: 32px;
	}
	/* .errorText {
		font-size: 12px;
		padding-left: 110px;
	} */
	.successText {
		font-size: 20px;
		margin: 0 0 0.75rem;
	}
	.loginButton {
		padding: 1rem;
	}
}

@media screen and (min-width: 1800px) {
	.loginButton {
		width: 12rem;
		font-size: 16px;
		margin: 0 auto;
		padding: 7px 5px;
		border-radius: 10px;
	}
	.loginColumn {
		width: 25rem;
		padding: 25px;
	}
	.loginPageLogo {
		width: 20rem;
	}
	.loginHeader {
		font-size: 24px;
	}
	.loginInput {
		padding: 15px;
		font-size: 16px;
	}
	/* .errorText {
		font-size: 12px;
		padding-left: 110px;
	} */
	.successText {
		font-size: 20px;
		margin: 0 0 0.75rem;
	}
	.loginButton {
		padding: 1rem;
	}
}
.loginPageLogo {
	width: 90%;
	height: 70%;
}
