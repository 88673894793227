.menu {
  width: 13rem;
  height: 100vh;
  position: relative;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  border-right: 1px solid #f2e0f7;
}

.menu.collapsed {
  width: 75px;
}

.menu-toggle {
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.menu-items {
  flex: 1 1;
  font-size: 12px;
  color: #333;
  padding: 10px;
}

.menu-item {
  margin-bottom: 15px;
}

.menu-item-header {
  cursor: pointer;
  height: 1rem;
  padding: 0.5rem 0 0.5rem 1rem;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  display: flex;
  font-size: 10;
}

.menu-item-header.c {
  cursor: pointer;
  height: 1rem;
  padding: 0.5rem 0 0.5rem 1rem;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  background-color: #f3effe;
  display: flex;
  font-size: 10;
}

.menu-item-header:hover {
  background-color: #f3effe;
}

.menu-item-content {
  margin: 10px;
  /* padding-left: 15px; */
  cursor: pointer;
}

.menu-sub {
  height: 27px;
  line-height: 26px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  /* color: #999; */
  /* display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer; */
}

.submenu-clicked {
  box-sizing: border-box;
  /* height: 1.5rem; */
  line-height: 26px;
  color: #1f43e5;
  display: flex;
  border-radius: 5px;
  background-color: #f3f5f9;
  font-weight: 500;
}

.icon-sub {
  padding: 0.325rem 0.5rem;
  position: absolute;
  left: 1.34rem;
  width: 1rem;
  border-radius: 8.8px;
  height: 1rem;
  fill: #ce9547;
}

.menu-sub:hover {
  background-color: #f3f5f9;
  cursor: pointer;
}

.icon-sub.c {
  background-color: #f3f5f9;
  cursor: pointer;
}

.icon-sub.c:hover {
  background-color: #f3f5f9;
  cursor: pointer;
}

.icon-sub.a:hover {
  background-color: #f3f5f9;
  cursor: pointer;
}

.icon-sub-clicked {
  background-color: #f3f5f9;
  cursor: pointer;
}

.companyLogo {
  padding: 0.25rem;
  height: 2.25rem;
  object-fit: contain;
}

.companyLogoText {
  height: 1rem;
  padding: 0.25rem 0;
  margin: auto 0;
}

.menu-list {
  box-sizing: border-box;
  border: 1px solid #ddd;
  margin: 10px;
  border-radius: 10px;
  font-size: 12px;
}

.menuTextSelected {
  height: 1rem;
  line-height: 1rem;
  margin-left: 1rem;
  display: inline-block;
  align-items: center;
}

.menu-list.collapsed {
  align-items: center;
  justify-content: center;
  color: #000;
  border: 1px solid #ddd;
  margin: 10px;
  border-radius: 10px;
  padding-bottom: 0.5rem;
}

.menu-top {
  display: flex;
  height: 2.95rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2e0f7;
}

.logo-container {
  display: flex;
  align-items: center;
}

.menu.collapsed.menuTextSelected {
  display: none;
}

.icon {
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
  position: absolute;
  left: 1.9rem;
}

.menuImage {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  position: relative;
  left: 1.45rem;
}

.menu-item-content.collapsed {
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 10px;
  padding-left: 15px;
  cursor: pointer;
}

.submenu {
  height: auto;
  margin-left: 1.4rem;
  /* margin-top: 0.45rem; */
  /* line-height: 1rem; */
  align-items: center;
}

.collapsed-icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
}
